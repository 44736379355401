<template>
    <main class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3">
                <div class="container">
                    <div class="grid align-items-center">
                        <div v-if="!isSubPage" class="col-fixed ">
                            <Button @click="$router.go(-1)" label="" className="p-button p-button-text "
                                icon="pi pi-arrow-left" />
                        </div>
                        <div class="col ">
                            <div class="">
                                <div class="flex align-items-center ">
                                    <div class="mr-3">
                                        <Avatar size="large" class="" icon="pi pi-user" />
                                    </div>
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Student Details</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section ">
            <div class="container">
                <div class="grid ">
                    <div class="col comp-grid">
                        <div>
                            <div style="min-height:100px">
                                <template v-if="pageReady">
                                    <div class="mb-3 grid justify-content-start">
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center p-3">
                                                <div class="col font-bold">
                                                    <image-viewer image-size="large" image-preview-size=""
                                                        :src="item.id_card_photo" width="100px" height="100px"
                                                        :num-display="1">
                                                    </image-viewer>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">First Name: </div>
                                                <div class="col font-bold">{{$utils.upper( item.first_name )}}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Middle Name: </div>
                                                <div class="col font-bold">{{$utils.upper( item.middle_name )}}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Last Name: </div>
                                                <div class="col font-bold">{{$utils.upper( item.last_name )}}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Email: </div>
                                                <div class="col font-bold">{{$utils.upper( item.email )}}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Gender: </div>
                                                <div class="col font-bold">{{$utils.upper( item.gender )}}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Date Of Birth: </div>
                                                <div class="col font-bold">{{$utils.humanDate( item.date_of_birth )}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Country: </div>
                                                <div class="col font-bold">{{ item.country }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Programme Of Study: </div>
                                                <div class="col font-bold">{{ item.programme_of_study }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Year Of Study: </div>
                                                <div class="col font-bold">{{ item.year_of_study }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Mobile Number: </div>
                                                <div class="col font-bold">{{ item.mobile_number }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Whatsapp Number: </div>
                                                <div class="col font-bold">{{ item.whatsapp_number }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Booking: </div>
                                                <div class="col font-bold">{{ item.booking }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Booking Date: </div>
                                                <div class="col font-bold">{{$utils.humanDatetime( item.booking_date )}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Booking Status: </div>
                                                <div class="col font-bold">{{ item.booking_status }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Booking Comments: </div>
                                                <div class="col font-bold">{{ item.booking_comments }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Payment Receipt: </div>
                                                <div class="col font-bold">{{ item.payment_receipt }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">User Comments: </div>
                                                <div class="col font-bold">{{ item.user_comments }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Room Allocation: </div>
                                                <div class="col font-bold">{{ item.room_allocation }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Room Number: </div>
                                                <div class="col font-bold">{{ item.room_number }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-content-center">
                                        <Menubar class="p-2" ref="actionMenu" :model="getActionMenuModel(item)" />
                                    </div>
                                </template>
                                <template v-if="loading">
                                    <div>
                                        <table class="p-datatable-table w-full">
                                            <tbody>
                                                <tr v-for="n in 5" :key="n">
                                                    <td class="text-left" style="width:100%">
                                                        <Skeleton height="2rem" class="m-2" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="p-3 text-center">
                                        <ProgressSpinner style="width:50px;height:50px" />
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<script setup>
    import { toRefs, onMounted } from 'vue';
    import { utils } from '@/utils';
    import { useApp } from '@/composables/app.js';
    import { useViewPage } from '@/composables/viewpage.js';
    const props = defineProps({
        id: [String, Number],
        primaryKey: {
            type: String,
            default: 'user_id',
        },
        pageName: {
            type: String,
            default: 'users',
        },
        routeName: {
            type: String,
            default: 'usersstudentview',
        },
        apiPath: {
            type: String,
            default: 'users/studentview',
        },
        editButton: {
            type: Boolean,
            default: true,
        },
        deleteButton: {
            type: Boolean,
            default: true,
        },
        exportButton: {
            type: Boolean,
            default: true,
        },
        titleBeforeDelete: {
            type: String,
            default: "Delete record",
        },
        msgBeforeDelete: {
            type: String,
            default: "Are you sure you want to delete this record?",
        },
        msgAfterDelete: {
            type: String,
            default: "Record deleted successfully",
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showFooter: {
            type: Boolean,
            default: true,
        },
        isSubPage: {
            type: Boolean,
            default: false,
        }
    });
    const app = useApp(props);
    const page = useViewPage(props); // page hook
    const { item, loading, pageReady } = toRefs(page.state);
    const { apiUrl, currentRecord } = page.computedProps
    const { load, deleteItem, exportPage, moveToNextRecord, moveToPreviousRecord } = page.methods;
    function getActionMenuModel(data) {
        return [
            {
                label: "Update Booking",
                command: (event) => { app.openPageDialog({ page: 'users/bookingedit', url: `/users/bookingedit/${data.user_id}`, closeBtn: true }) },
                icon: "pi pi-calendar"
            },
            {
                label: "Allocate Room",
                command: (event) => { app.openPageDialog({ page: 'users/allocateroom', url: `/users/allocateroom/${data.user_id}`, persistent: true, closeBtn: true }) },
                icon: "pi pi-reply"
            }
        ]
    }
    onMounted(() => {
        const pageTitle = "Student Details";
        app.setPageTitle(props.routeName, pageTitle); // set browser page title
        load();
    });
</script>
<style scoped>
</style>